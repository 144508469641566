import gql from "graphql-tag";

const GET_MSC_CUSTOMERS = gql`
  query CustomerList($filters: ManagedServicesConnectorCustomersInput!) {
    finance {
      managedServicesConnector {
        customers(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result {
            accountNumber
            convertedDate
            id
            invalidityReasons
            name
            newCustomer
            subAccountInfo {
              activationDate
              blacklisted
              deactivationDate
              externallyManaged
              id
              name
            }
            upload
          }
        }
      }
    }
  }
`;

export { GET_MSC_CUSTOMERS };
