import gql from "graphql-tag";

const MUTATION_MSC_CUSTOMERS = gql`
  mutation MscCustomer($input: ManagedServicesConnectorCustomerUpdateInput!) {
    managedServicesConnectorCustomerUpdate(input: $input) {
      metadata {
        conversationId
        message
        statusCode
        statusText
      }
      result
    }
  }
`;

export { MUTATION_MSC_CUSTOMERS };
