import NotificationService from "@/core/services/notification.service";
import DialogService from "@/core/services/dialog.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { CLOUD_PROVIDER_ENUM, COUNTRY_ENUM } from "@/core/constants";
import { GET_MSC_CUSTOMERS } from "./graphql/customers.queries";
import { MUTATION_MSC_CUSTOMERS } from "./graphql/customers.mutations";

import { ENVIRONMENT_ENUM } from "@/core/constants";

//#region Vee Validation
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import dayjs from "dayjs";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} is required"
});
//#endregion

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isLoading: false,
      skipQuery: true,
      dialog: false,
      search: "",
      cloudProviderList: [
        { name: CLOUD_PROVIDER_ENUM.AWS },
        { name: CLOUD_PROVIDER_ENUM.AZURE }
      ],
      countryList: Object.entries(COUNTRY_ENUM).map(([name]) => ({
        name
      })),
      originalList: [],
      selectedCloudProvider: {},
      selectedCountry: null,
      customers: [],
      selectedCustomer: {},
      selectedSubscriptions: [],
      tableHeaders: [
        { text: "Valid", value: "valid" },
        { text: "Upload", value: "upload" },
        { text: "Name", value: "name" },
        { text: "Account Number", value: "accountNumber" },
        { text: "Active Subscriptions", value: "status" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      dialogTableHeaders: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Activation Date", value: "activationDate" },
        { text: "Deactivation Date", value: "deactivationDate" },
        { text: "Status", value: "blacklistedState" }
      ]
    };
  },
  apollo: {
    customers: {
      client: "connectClient",
      query: GET_MSC_CUSTOMERS,
      variables() {
        return {
          filters: {
            cloudProvider: {
              value: [this.selectedCloudProvider.name]
            },
            country: {
              value: [this.selectedCountry.name]
            }
          }
        };
      },
      update(data) {
        let customers = data.finance.managedServicesConnector.customers;
        if (!customers.result) {
          NotificationService.error(
            `${customers.metadata.message} (${customers.metadata.statusCode})`
          );
          return [];
        } else {
          return this.convertMscCustomerToDatasource(customers.result);
        }
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.customers.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        // noinspection JSIgnoredPromiseFromCall
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  methods: {
    isDataModified() {
      // noinspection JSCheckFunctionSignatures
      return this.originalList.any(
        x =>
          x.upload !==
            this.customers
              .where(c => c.accountNumber === x.accountNumber)
              .first()["upload"] ||
          x.convertedDate !==
            this.customers
              .where(c => c.accountNumber === x.accountNumber)
              .first()["convertedDate"]
      );
    },
    allowToggleUpload() {
      return process.env.VUE_APP_NODE_ENV !== ENVIRONMENT_ENUM.PRODUCTION;
    },
    isDateBeforeToday(date) {
      const today = dayjs(new Date());
      const pastDate = dayjs(date);
      return pastDate.isBefore(today);
    },
    convertMscCustomerToDatasource(data) {
      this.originalList = JSON.parse(JSON.stringify(data));
      data.forEach(item => {
        item.subAccountInfo.forEach(subAccount => {
          subAccount["blacklistedState"] = subAccount.blacklisted ? 1 : 0;
          subAccount["activationDateEnabled"] = this.isDateBeforeToday(
            subAccount["activationDate"]
          );
          subAccount["deactivationDateEnabled"] = this.isDateBeforeToday(
            subAccount["deactivationDate"]
          );
        });
      });

      return data;
    },
    async queryMscCustomers() {
      let mscCustomers = await this.$apollo.query({
        client: "connectClient",
        query: GET_MSC_CUSTOMERS,
        variables: {
          filters: {
            cloudProvider: {
              value: [this.selectedCloudProvider.name]
            },
            country: {
              value: [this.selectedCountry.name]
            }
          }
        }
      });
      this.customers = this.convertMscCustomerToDatasource(
        mscCustomers.data.finance.managedServicesConnector.customers.result
      );
    },
    saveConvertedDate(item) {
      item.convertedDate = item.new_date;
    },
    updateTableHeaders() {
      let index = this.tableHeaders.findIndex(
        item => item.value === "convertedDate"
      );

      if (
        this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AZURE &&
        this.selectedCountry.name === COUNTRY_ENUM.BE &&
        index === -1
      ) {
        this.tableHeaders.splice(5, 0, {
          text: "Converted Date",
          value: "convertedDate"
        });
      } else if (
        this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AZURE &&
        this.selectedCountry.name === COUNTRY_ENUM.BE &&
        index !== -1
      ) {
        //pass
      } else {
        if (index !== -1) {
          this.tableHeaders.splice(index, 1);
        }
      }
    },
    updateDialogTableHeaders() {
      let index = this.dialogTableHeaders.findIndex(
        item => item.value === "externallyManaged"
      );

      if (
        this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AWS &&
        index === -1
      ) {
        this.dialogTableHeaders.splice(1, 0, {
          text: "External",
          value: "externallyManaged"
        });
      } else if (
        this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AWS &&
        index !== -1
      ) {
        //pass
        return;
      } else {
        if (index !== -1) {
          this.dialogTableHeaders.splice(index, 1);
        }
      }
    },
    cloudProviderSelectionChanged() {
      this.customers = [];
      this.selectedCountry = null;
    },
    countrySelectionChanged() {
      this.customers = [];
    },
    loadCustomers() {
      this.updateTableHeaders();
      this.updateDialogTableHeaders();
      this.$apollo.queries.customers.skip = false;
      // noinspection JSIgnoredPromiseFromCall
      this.$apollo.queries.customers.refetch();
    },
    closeDialog() {
      this.dialog = false;
    },
    getVariablesForMscCustomerMutation(buttonLocation) {
      let customerData = {};

      // 'main' is the customer overview page, 'dialog' is the account detail view
      if (buttonLocation === "main") {
        this.customers.forEach(customer => {
          this.originalList.forEach(original => {
            if (
              customer.accountNumber === original.accountNumber &&
              (customer.upload !== original.upload ||
                customer.convertedDate !== original.convertedDate)
            ) {
              customerData[customer.accountNumber] = {
                convertedDate: customer.convertedDate,
                upload: customer.upload
              };
            }
          });
        });
      } else {
        if (this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AZURE) {
          let subscriptions = [];
          this.selectedCustomer.subAccountInfo.forEach(subAccount => {
            subscriptions.push({
              activationDate: subAccount.activationDate,
              blacklisted: subAccount.blacklistedState === 1,
              convertedDate: subAccount.convertedDate,
              deactivationDate: subAccount.deactivationDate,
              subscriptionId: subAccount.id
            });
          });
          customerData = {
            [this.selectedCustomer.accountNumber]: {
              tenants: [
                {
                  tenantId: this.selectedCustomer.id,
                  subscriptions: subscriptions
                }
              ]
            }
          };
        } else if (
          this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AWS
        ) {
          let accounts = [];
          this.selectedCustomer.subAccountInfo.forEach(subAccount => {
            accounts.push({
              accountId: subAccount.id,
              activationDate: subAccount.activationDate,
              blacklisted: subAccount.blacklistedState === 1,
              deactivationDate: subAccount.deactivationDate,
              externallyManaged: subAccount.externallyManaged
            });
          });
          customerData = {
            [this.selectedCustomer.accountNumber]: { accounts: accounts }
          };
        }
      }

      return {
        cloudProvider: this.selectedCloudProvider.name,
        country: this.selectedCountry.name,
        customerData: JSON.stringify(customerData)
      };
    },
    async saveChangesButtonClick(buttonLocation) {
      const message = "Are you sure you want to submit your changes?";
      await this.callCustomersMutation(message, buttonLocation);
    },
    async callCustomersMutation(message, buttonLocation) {
      if (
        await DialogService.confirm(this, "Confirmation", message, {
          manualControl: true
        })
      ) {
        await this.$apollo
          .mutate({
            client: "connectClient",
            mutation: MUTATION_MSC_CUSTOMERS,
            variables: {
              input: this.getVariablesForMscCustomerMutation(buttonLocation)
            }
          })
          .then(async res => {
            if (
              res.data?.managedServicesConnectorCustomerUpdate?.metadata
                ?.conversationId
            ) {
              await this.queryMscCustomers();
              DialogService.close(this);
              this.dialog = false;
              setTimeout(() => {
                NotificationService.success(
                  "Successfully submitted your change"
                );
              }, 100);
            }
          });
      }
    },
    openSubscriptionDialog(item) {
      this.dialog = true;
      this.selectedCustomer = JSON.parse(JSON.stringify(item));
    },
    getCustomerSubscriptionInfoText(item) {
      if (!item.subAccountInfo) {
        return;
      }

      let activeSubscriptions = item.subAccountInfo.filter(
        item => item.blacklistedState === 0
      );
      return (
        activeSubscriptions.length + " out of " + item.subAccountInfo.length
      );
    },
    activationDateChanged(item) {
      const activationDate = dayjs(item.newActivationDate);
      const deactivationDate = dayjs(item.deactivationDate);
      if (activationDate.isAfter(deactivationDate)) {
        NotificationService.error(
          "Activation date must always be earlier than de-activation date."
        );
        return;
      }

      item.activationDate = item.newActivationDate;
    },
    deactivationDateChanged(item) {
      const activationDate = dayjs(item.activationDate);
      const deactivationDate = dayjs(item.newDeactivationDate);
      if (deactivationDate.isBefore(activationDate)) {
        NotificationService.error(
          "De-activation date must always be greater than activation date."
        );
        return;
      }

      item.deactivationDate = item.newDeactivationDate;
    }
  },
  mounted() {
    // noinspection JSIgnoredPromiseFromCall
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "Navigation Menu Group",
        route: "/group/navigation-menu"
      },
      {
        title: "Customers"
      }
    ]);
  }
};
